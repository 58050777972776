import Image from 'next/image';
import Link from 'next/link';
import React, { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { getClickLinkParams } from '../../../../common/utils/appLinkUtil';

import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import PGMBoxBTNArea from './PGMBoxBTNArea';

export default function pgm_Box(props) {
  const [swiperRef, setSwiperRef] = useState(null);
  let [maxPageCount, setMaxPageCount] = useState(0);
  let [naviCount, setNaviCount] = useState(1);

  //  let subscribeClick = false;

  useEffect(() => {
    if (swiperRef != null) {
      swiperRef.slideTo(0, 0); //TAB 영역 누르면 스와이퍼 초기화
    }

    setMaxPageCount(Math.ceil(props.data.contents.length / 5));

  }, [])

  useEffect(() => {
    //alert('www');
    window.__SBSLogModuleDataLayer = window.__SBSLogModuleDataLayer || [];
  }, []);
  //link로그를
  const handleClick = (a,b,c,d,e) => {
    //통계에 넙겨줄 값을정리한다,상단여역 pgmbox 프로그램 / 메뉴 / 플레이톡이 있음
    window.__SBSLogModuleDataLayer.push({
      type: 'content',
      option: {
        moduleId: '큐레이션 모듈', //모듈 아이디 없으면
        moduleTitle: e, //모듈 제목 (Require)
        moduleSubTitle: '프로그램테마 큐레이션',
        moduleActive: 'C', //콘텐츠 선택 정보 Click: C, Hover: H
        moduleSeq: props.loc_idx, //페이지에서 해당 모듈의 위치
        moduleItemSeq: c, //모듈 내에서 콘텐츠의 위치
        programId: b.pgm_id, //프로그램의 아이디 (추가)
        programTitle: b.pgm_nm, //프로그램의 아이디 (추가)
        contentId:  b.pgm_id,//식별자
        contentTitle: b.img_attr, //콘텐츠의 제목  (Require)
        contentNumber: '',
        contentType: 'PR', //콘텐츠의 타입 (별도 표 참조)
      }
    });
  };

  const handleClick_custom = (a,b,c,d) => {

    window.__SBSLogModuleDataLayer.push({
      type: 'click',
      option: {
        clickName: '스브스프리미엄 전체보기',
        clickType: 'NC',
        value1: '',
        value2: '',
        value3: '',
        value4: '',
        value5: ''
      }
    });

  };

  return (
    <>
      <div className="mainContents-typeWhite">
        <div className="contentsBlock_w">
          <div className="moduleTitleline_w">
            <h2 className="moduleTitleline-Title">{props.data.list_title}</h2>
            <a href={props.data.alllink_url} className="moduleTitleline-linkMore" style={{ visibility: (props.data.alllink_yn == 'Y' && props.data.alllink_url != '' && props.data.alllink_url != null) ? 'visible' : 'hidden' }}>{props.data.alllink_title}</a>
          </div>
          <div className={"listModule_w_program program" + props.loc_idx}>
            <button type="button" aria-label="다음 콘텐츠 보기" className="btnTypeList-Next swiper-button-next" onClick={() => setNaviCount(naviCount + 1)}>
              <span className="iconSvg_arrow"><i className="hide">다음</i></span>
              <span className="pagingNumber_w"><strong className="pagingNumber-now">{naviCount}</strong>/{maxPageCount}</span>
            </button>

            <Swiper
              onSwiper={setSwiperRef}
              slidesPerView={5}
              spaceBetween={25}
              slidesPerGroup={5}
              loop={false}
              pagination={{ // 페이징 설정
                el: `.program${props.loc_idx} .swiper-pagination`,
                clickable: false, // 페이징을 클릭하면 해당 영역으로 이동, 필요시 지정해 줘야 기능 작동
              }}
              navigation={
                {
                  nextEl: `.program${props.loc_idx} .swiper-button-next`,
                  prevEl: `.program${props.loc_idx} .swiper-button-prev`,
                }
              }
              modules={[Autoplay, Pagination, Navigation]}
              className="listModuleSlider"
              wrapperClass="SliderContent"
            >
              {
                props.data.contents.map((item, index) => {

                  // let subscribeClick = false;
                  // let [subscribeClick, setSubscribeClick] = useState(false);
                  return (
                    < SwiperSlide >
                      <div className="listModuleItem swiper-slide">
                        <div className="modulePosterWrap">
                          <Link href={item.link_url} >
                            <a   onClick={(e) => handleClick(e, item,index,'인기', props.data.list_title)} className="mp_link">
                              <div className="mp_image_w">
                                <div className="mp_image_inner">
                                  <img src={item.img_url} className="mp_image" alt={item.img_attr} />
                                </div>
                                <div className="mb_label_w">
                                  {
                                    item.free_yn == 'Y' ? (<><span className="mb_label_free">FREE</span></>) : (<></>)
                                  }
                                  {
                                    item.age19_yn == 'Y' ? (<><span className="mb_label_19">19+</span></>) : (<></>)
                                  }
                                </div>
                              </div>
                              <div className="mp_info_w">
                                <span className="mpif_title">{item.pgm_nm}</span>
                                {
                                  item.new_yn == 'Y' ? (<><span className="mp_label_new"><i className="hide">신작</i></span></>) : (<></>)
                                }
                              </div>
                            </a>
                          </Link>

                          <PGMBoxBTNArea key={'mb_' + index} data={item} clickParams={props.clickParamLink} />

                        </div>
                      </div>
                    </SwiperSlide>
                  );
                })
              }
            </Swiper>
            <div className="swiper-pagination" style={{ visibility: (maxPageCount == 1) ? 'hidden' : 'visible' }}></div>
            <button type="button" aria-label="이전 콘텐츠 보기" className="btnTypeList-Prev swiper-button-prev" onClick={() => setNaviCount(naviCount - 1)}>
              <span className="iconSvg_arrow"><i className="hide">이전</i></span>
              <span className="pagingNumber_w"><strong className="pagingNumber-now">{naviCount}</strong>/{maxPageCount}</span>
            </button>
          </div>

        </div>
      </div>
    </>

  );

}