import Image from 'next/image';
import Link from 'next/link';
import React, { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { toPercentTime, toPercentTimeDesc, updateQueryStringParameter } from '../../../../common/utils/convertUtil';

export default function popvod_Box(props) {

  const [liveTab, setLiveTab] = useState(0);
  const [swiperRef, setSwiperRef] = useState(null);

  let [maxPageCount, setMaxPageCount] = useState(0);
  let [naviCount, setNaviCount] = useState(1);

  useEffect(() => {
    if (swiperRef != null) {
      swiperRef.slideTo(0, 0); //TAB 영역 누르면 스와이퍼 초기화
    }
    //pc는 한화면에 4개
    if (liveTab == 0) {
      setMaxPageCount(Math.ceil(props.data.contents1.length / 4));
      setNaviCount(1);
    } else {
      setMaxPageCount(Math.ceil(props.data.contents2.length / 4));
      setNaviCount(1);
    }

  }, [liveTab])

  useEffect(() => {



  }, [naviCount])

  useEffect(() => {
    //alert('www');
    window.__SBSLogModuleDataLayer = window.__SBSLogModuleDataLayer || [];
  }, []);
  //link로그를
  const handleClick = (a,b,c,d) => {
    let conver_current_s= (parseInt(c));
    let conver_current_s2 = (parseInt(props.loc_idx));
    //통계에 넙겨줄 값을정리한다,상단여역 pgmbox 프로그램 / 메뉴 / 플레이톡이 있음
    window.__SBSLogModuleDataLayer.push({
      type: 'content',
      option: {
        moduleId: 'VOD', //모듈 아이디 없으면
        moduleTitle: '지금 뜨는 VOD', //모듈 제목 (Require)
        moduleSubTitle: d,
        moduleActive: 'C', //콘텐츠 선택 정보 Click: C, Hover: H
        moduleSeq: conver_current_s2, //페이지에서 해당 모듈의 위치
        moduleItemSeq: conver_current_s, //모듈 내에서 콘텐츠의 위치
        programId: b.pgm_id, //프로그램의 아이디 (추가)
        programTitle: b.pgm_nm, //프로그램의 제목 (추가)
        contentId: 'POPVOD',//식별자
        contentTitle: b.img_attr, //콘텐츠의 제목  (Require)
        contentNumber: b.srs_no,
        contentType: 'V', //콘텐츠의 타입 (별도 표 참조)
      }
    });
  };

  const handleClick_custom = (a,b,c,d) => {


    //통계에 넙겨줄 값을정리한다,상단여역 pgmbox 프로그램 / 메뉴 / 플레이톡이 있음



    window.__SBSLogModuleDataLayer.push({
      type: 'click',
      option: {
        clickName: '지금뜨는VOD 이용권구매하기',
        clickType: 'NC',
        value1: '',
        value2: '',
        value3: '',
        value4: '',
        value5: ''
      }
    });

  };

  return (
    <>
      {/* <!-- 지금 뜨는 VOD --> */}
      <div className="mainContents-typeWhite">
        {/* 탭영역 */}
        <div className="contentsBlock_w">
          <div className="moduleTitleline_w">
            <div className="moduleTitleline-Left">
              <h2 className="moduleTitleline-Title">{props.data.list_title}</h2>
              <a href="#none" onClick={() => setLiveTab(0)} className={`moduleTitlelineTab-Link${liveTab == 0 ? ' current' : ''}`}>인기</a>
              <a href="#none" onClick={() => setLiveTab(1)} className={`moduleTitlelineTab-Link${liveTab == 1 ? ' current' : ''}`}>최신</a>
            </div>
            <a href={props.data.alllink_url} onClick={(e) => handleClick_custom(e, '','0','all')} className="btnType-passBuy"><span className="iconSvg_pass"><i className="hide">이용권 구매하기</i></span></a>
          </div>

          {
            liveTab == 0
              ? (
                <>
                  <div className={"listModule_w_vodCuration vodCuration" + props.loc_idx}>
                    <button type="button" aria-label="다음 콘텐츠 보기" className="btnTypeList-Next swiper-button-next" onClick={() => setNaviCount(naviCount + 1)}>
                      <span className="iconSvg_arrow"><i className="hide">다음</i></span>
                      <span className="pagingNumber_w"><strong className="pagingNumber-now">{naviCount}</strong>/{maxPageCount}</span>
                    </button>

                    <Swiper
                      observer={true}
                      observeParents={true}
                      onSwiper={setSwiperRef}
                      slidesPerView={4}
                      spaceBetween={24}
                      slidesPerGroup={4}
                      loop={false}
                      pagination={{ // 페이징 설정
                        el: `.vodCuration${props.loc_idx} .swiper-pagination`,
                        clickable: false, // 페이징을 클릭하면 해당 영역으로 이동, 필요시 지정해 줘야 기능 작동
                      }}
                      navigation={
                        {
                          nextEl: `.vodCuration${props.loc_idx} .swiper-button-next`,
                          prevEl: `.vodCuration${props.loc_idx} .swiper-button-prev`,
                        }
                      }
                      modules={[Autoplay, Pagination, Navigation]}
                      className="listModuleSlider"
                      wrapperClass="SliderContent"
                    >

                      {
                        props.data.contents1.map((item, index) => {

                          const handleOpenPopup = () => {
                            //  485 * 928
                            const popup = window.open(item.talk_link, '타이틀명을 입력하시오', 'top=0, left=0, width=485, height=928, status=no, menubar=no, toolbar=no, resizable=no');
                          }

                          return (
                            < SwiperSlide key={'popvodslide_' + index}>
                              <div className="listModuleItem swiper-slide">
                                <div className="moduleBoxWrap_vod">
                                  <Link href={item.link_url} >
                                    <a  onClick={(e) => handleClick(e, item,index,'인기')}  target={item.link_new_yn == 'Y' ? '_blank' : ''} className="mb_link">
                                      <div className="mb_image_w">
                                        <span className="iconSvg_play"><i className="hide">VOD</i></span>
                                        <div className="mb_image_inner">
                                          <img src={item.img_url} className="mb_image" alt="웹접근성을 위해 이미지에 맞는 텍스트를 제공해주세요." />
                                        </div>
                                        <div className="mb_label_w">
                                          {
                                            item.free_yn == 'Y' ? (<><span className="mb_label_free">FREE</span></>) : (<></>)
                                          }
                                          {
                                            item.age19_yn == 'Y' ? (<><span className="mb_label_19">19+</span></>) : (<></>)
                                          }
                                        </div>
                                      </div>

                                      <div className="mb_info_w">
                                        <span className="mbif_title">{item.pgm_nm}</span>
                                        <div className="mbif_line2">
                                          <span className="mbif_subinfo">{item.srs_no}회</span>
                                          <span className="mbif_date">{item.brd_beg_dd}</span>
                                        </div>
                                      </div>
                                    </a>
                                  </Link>
                                </div>
                              </div>
                            </SwiperSlide>
                          );
                        })
                      }

                    </Swiper>
                    <div className="swiper-pagination"></div>
                    <button type="button" aria-label="이전 콘텐츠 보기" className="btnTypeList-Prev swiper-button-prev" onClick={() => setNaviCount(naviCount - 1)}>
                      <span className="iconSvg_arrow"><i className="hide">이전</i></span>
                      <span className="pagingNumber_w"><strong className="pagingNumber-now">{naviCount}</strong>/{maxPageCount}</span>
                    </button>
                  </div>
                </>

              )
              :
              (
                <>
                  <div className={"listModule_w_vodCuration vodCuration" + props.loc_idx}>
                    <button type="button" aria-label="다음 콘텐츠 보기" className="btnTypeList-Next swiper-button-next" onClick={() => setNaviCount(naviCount + 1)}>
                      <span className="iconSvg_arrow"><i className="hide">다음</i></span>
                      <span className="pagingNumber_w"><strong className="pagingNumber-now">{naviCount}</strong>/{maxPageCount}</span>
                    </button>

                    <Swiper
                      observer={true}
                      observeParents={true}
                      onSwiper={setSwiperRef}
                      slidesPerView={4}
                      spaceBetween={24}
                      slidesPerGroup={4}
                      loop={false}
                      pagination={{ // 페이징 설정
                        el: `.vodCuration${props.loc_idx} .swiper-pagination`,
                        clickable: false, // 페이징을 클릭하면 해당 영역으로 이동, 필요시 지정해 줘야 기능 작동
                      }}
                      navigation={
                        {
                          nextEl: `.vodCuration${props.loc_idx} .swiper-button-next`,
                          prevEl: `.vodCuration${props.loc_idx} .swiper-button-prev`,
                        }
                      }
                      modules={[Autoplay, Pagination, Navigation]}
                      className="listModuleSlider"
                      wrapperClass="SliderContent"
                    >

                      {
                        props.data.contents2.map((item, index) => {

                          const handleOpenPopup = () => {
                            //  485 * 928
                            const popup = window.open(item.talk_link, '타이틀명을 입력하시오', 'top=0, left=0, width=485, height=928, status=no, menubar=no, toolbar=no, resizable=no');
                          }

                          return (
                            < SwiperSlide key={'popvodslide_' + index}>
                              <div className="listModuleItem swiper-slide">
                                <div className="moduleBoxWrap_vod">
                                  <Link href={item.link_url} >
                                    <a onClick={(e) => handleClick(e, item,index,'최신')} target={item.link_new_yn == 'Y' ? '_blank' : ''} className="mb_link">
                                      <div className="mb_image_w">
                                        <span className="iconSvg_play"><i className="hide">VOD</i></span>
                                        <div className="mb_image_inner">
                                          <img src={item.img_url} className="mb_image" alt="웹접근성을 위해 이미지에 맞는 텍스트를 제공해주세요." />
                                        </div>
                                        <div className="mb_label_w">
                                          {
                                            item.free_yn == 'Y' ? (<><span className="mb_label_free">FREE</span></>) : (<></>)
                                          }
                                          {
                                            item.age19_yn == 'Y' ? (<><span className="mb_label_19">19+</span></>) : (<></>)
                                          }
                                        </div>
                                      </div>
                                      <div className="mb_info_w">
                                        <span className="mbif_title">{item.pgm_nm}</span>
                                        <div className="mbif_line2">
                                          <span className="mbif_subinfo">{item.srs_no}회</span>
                                          <span className="mbif_date">{item.brd_beg_dd}</span>
                                        </div>
                                      </div>
                                    </a>
                                  </Link>
                                </div>
                              </div>
                            </SwiperSlide>
                          );
                        })
                      }

                    </Swiper>
                    <div className="swiper-pagination"></div>
                    <button type="button" aria-label="이전 콘텐츠 보기" className="btnTypeList-Prev swiper-button-prev" onClick={() => setNaviCount(naviCount - 1)}>
                      <span className="iconSvg_arrow"><i className="hide">이전</i></span>
                      <span className="pagingNumber_w"><strong className="pagingNumber-now">{naviCount}</strong>/{maxPageCount}</span>
                    </button>
                  </div>
                </>
              )
          }
        </div>
      </div >
    </>
  );




}