import Image from 'next/image';
import Link from 'next/link';
import React, { useEffect, useState, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { getClickLinkParams } from '../../../../common/utils/appLinkUtil';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';

export default function banner_Box(props) {

    const [swiperRef, setSwiperRef] = useState(null);
    let [maxPageCount, setMaxPageCount] = useState(0);
    let [naviCount, setNaviCount] = useState(1);

    useEffect(() => {
        if (swiperRef != null) {
            swiperRef.slideTo(0, 0); //TAB 영역 누르면 스와이퍼 초기화
        }
        setMaxPageCount(Math.ceil(props.data.contents.length / 5) + 1);
    }, [])

    useEffect(() => {
        //alert('www');
        window.__SBSLogModuleDataLayer = window.__SBSLogModuleDataLayer || [];
    }, []);

    const handleClick_custom = (a,b,c,d) => {


        //통계에 넙겨줄 값을정리한다,상단여역 pgmbox 프로그램 / 메뉴 / 플레이톡이 있음



        window.__SBSLogModuleDataLayer.push({
            type: 'click',
            option: {
                clickName: '홍보 배너',
                clickType: 'NC',
                value1: b.pc_img_attr,
                value2: '',
                value3: '',
                value4: '',
                value5: ''
            }
        });

    };


    try {
        if (props.data.contents.length == 1) {
            return (
                <>
                    <div className="mainContents-typeWhite">
                  <div className="contentsBlock_w">
                    <div className="moduleTitleline_w hide">
                                <h2 className="moduleTitleline-Title">{props.data.list_title}</h2>
                            </div>
                    <div className="SBSPLAY_Banner_w">
                                <a href={props.data.contents[0].link_url}>
                        {/* <img src="https://via.placeholder.com/1200x309" className="bnr_image" alt="배너광고영역" /> */}
                        <img src={props.data.contents[0].pc_img_url} className="bnr_image" alt="배너광고영역" />
                                </a>
                            </div>
                        </div>
                    </div>
                </>
            );
        } else {
            return (
                <>
                    <div className="mainContents-typeWhite">
                        <div className="contentsBlock_w">
                            <div className="moduleTitleline_w hide">
                                <h2 className="moduleTitleline-Title">{props.data.list_title}</h2>
                            </div>
                            <div className={"listModule_w_houseBnr houseBnr" + props.loc_idx}>
                                <button type="button" aria-label="다음 콘텐츠 보기" className="btnTypeList-Next swiper-button-next" onClick={() => setNaviCount(naviCount + 1)}>
                                    <span className="iconSvg_arrow"><i className="hide">다음</i></span>
                                    <span className="pagingNumber_w"><strong className="pagingNumber-now">{naviCount}</strong>/{maxPageCount}</span>
                                </button>
                                <Swiper
                                    onSwiper={setSwiperRef}
                                    slidesPerView={2}
                                    spaceBetween={24}
                                    slidesPerGroup={2}
                                    loop={false}
                                    pagination={{ // 페이징 설정
                                        el: `.houseBnr${props.loc_idx} .swiper-pagination`,
                                        clickable: false, // 페이징을 클릭하면 해당 영역으로 이동, 필요시 지정해 줘야 기능 작동
                                    }}
                                    navigation={
                                        {
                                            nextEl: `.houseBnr${props.loc_idx} .swiper-button-next`,
                                            prevEl: `.houseBnr${props.loc_idx} .swiper-button-prev`,
                                        }
                                    }
                                    modules={[Autoplay, Pagination, Navigation]}
                                    className="listModuleSlider"
                                    wrapperClass="SliderContent"
                                >
                                    {
                                        props.data.contents.map((item, index) => {
                                            return (
                                                < SwiperSlide >
                                                    <div className="listModuleItem swiper-slide">
                                                        <div className="moduleBoxWrap_houseBnr">
                                                            <Link href={getClickLinkParams(item.link_url, props.clickParams)} >
                                                                <a  onClick={(e) => handleClick_custom(e, item,'0','all')}       target={item.link_new_yn === 'Y' ? '_blank' : ''} className="mb_link">
                                                                    <img src={item.pc_img_url} className="mb_image" alt={item.pc_img_attr} />
                                                                </a>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                            );
                                        })
                                    }
                                </Swiper>
                      <div className="swiper-pagination"></div>
                                <button type="button" aria-label="이전 콘텐츠 보기" className="btnTypeList-Prev swiper-button-prev" onClick={() => setNaviCount(naviCount - 1)}>
                                    <span className="iconSvg_arrow"><i className="hide">이전</i></span>
                                    <span className="pagingNumber_w"><strong className="pagingNumber-now">{naviCount}</strong>/{maxPageCount}</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </>
            );
        }
    } catch (error) {
        return (<></>);
    }





}