import Axios from 'axios';
import Image from 'next/image';
import Link from 'next/link';
import React, { useEffect, useRef, useState } from 'react';

export default function noticeTopBanner(props) {
  //console.log('notice top banner--')
  //console.log(props)



  useEffect(() => {

    const banner = document.querySelector('.noticeTopBanner_w');
    const closedBtns = document.querySelector('.btn_noticeClosed');

    closedBtns.addEventListener('click', (e) => {
      e.preventDefault();
      banner.classList.remove('current');
    }, false);

  }, []);
  useEffect(() => {
    //alert('www');
    window.__SBSLogModuleDataLayer = window.__SBSLogModuleDataLayer || [];
  }, []);
  //link로그를

  const handleClick_custom = (a,b,c,d) => {

    window.__SBSLogModuleDataLayer.push({
      type: 'click',
      option: {
        clickName: '상단 1줄 공지',
        clickType: 'NC',
        value1: props.data[0].notice_content,
        value2: '',
        value3: '',
        value4: '',
        value5: ''
      }
    });

  };


  return (
    <>
      {/*<!-- 긴급공지 -->*/}
      <div className={`noticeTopBanner_w current`}>
        {/*<!-- current 추가시 노출됨 -->*/}
        <div className="noticeTopBanner_inner">
          <Link href={props.data[0].link_url} >
            <a     onClick={(e) => handleClick_custom(e, '','0','all')} target={props.data[0].link_new_yn === 'Y' ? '_blank' : ''} className="noticeTopBanner-link">
              <span className="iconSvg_emergency"></span>
              <p className="noticeTopBanner-text">
               {props.data[0].notice_content}
              </p>
            </a>
          </Link>
          <button type="button" className="btn_noticeClosed">
            <span className="iconSvg_closed"><i className="hide">닫기</i></span>
          </button>
        </div>
      </div>
    </>
  );

}


