import Image from 'next/image';
import Link from 'next/link';
import React, { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { getClickLinkParams } from '../../../../common/utils/appLinkUtil';
// import ShareBox from '../../hotissue/box/ShareBox';


export default function event_Box(props) {

  const [width, setWidth] = useState(282);
  const [height, setHeight] = useState(0);

  const [swiperRef, setSwiperRef] = useState(null);
  let [maxPageCount, setMaxPageCount] = useState(0);
  let [naviCount, setNaviCount] = useState(1);


  useEffect(() => {
    if (swiperRef != null) {
      swiperRef.slideTo(0, 0); //TAB 영역 누르면 스와이퍼 초기화
    }
    setMaxPageCount(Math.ceil(props.data.contents.length / 3));

  }, [])
  useEffect(() => {
    //alert('www');
    window.__SBSLogModuleDataLayer = window.__SBSLogModuleDataLayer || [];
  }, []);

  const handleClick_custom = (a,b,c,d) => {

    window.__SBSLogModuleDataLayer.push({
      type: 'click',
      option: {
        clickName: '이벤트 전체보기',
        clickType: 'NC',
        value1: '',
        value2: '',
        value3: '',
        value4: '',
        value5: ''
      }
    });

  };

  const handleClick = (a,b,c,d) => {

    //통계에 넙겨줄 값을정리한다,상단여역 pgmbox 프로그램 / 메뉴 / 플레이톡이 있음
    window.__SBSLogModuleDataLayer.push({
      type: 'click',
      option: {
        clickName: '이벤트',
        clickType: 'NC',
        value1: b.title,
        value2: '',
        value3: '',
        value4: '',
        value5: ''
      }
    });
  };

  const handleImageLoad = (e) => {
    const nHeight = e.naturalHeight;
    const nWidth = e.naturalWidth;
    const scale = Math.ceil(e.naturalWidth / 282);
    const scaleHeight = Math.ceil(nHeight / scale);
    setHeight(scaleHeight);
    if (props.forceUpdate) {
      props.forceUpdate();
    }
  };

  return (
    <>
      <div className="contentsBlock_w">
        <div className="moduleTitleline_w">
          <h2 className="moduleTitleline-Title">{props.data.list_title}</h2>
          <a href={props.data.alllink_url}   onClick={(e) => handleClick_custom(e, '','0','all')} className="moduleTitleline-linkMore" style={{ visibility: (props.data.alllink_yn == 'Y' && props.data.alllink_url != '' && props.data.alllink_url != null) ? 'visible' : 'hidden' }}>{props.data.alllink_title}</a>
        </div>
        <div className={"listModule_w_event event" + props.loc_idx}>
          <button type="button" aria-label="다음 콘텐츠 보기" className="btnTypeList-Next swiper-button-next" onClick={() => setNaviCount(naviCount + 1)}>
            <span className="iconSvg_arrow"><i className="hide">다음</i></span>
            <span className="pagingNumber_w"><strong className="pagingNumber-now">{naviCount}</strong>/{maxPageCount}</span>
          </button>

          <Swiper
            onSwiper={setSwiperRef}
            slidesPerView={3}
            spaceBetween={24}
            slidesPerGroup={3}
            loop={false}
            pagination={{ // 페이징 설정
              el: `.event${props.loc_idx} .swiper-pagination`,
              clickable: false, // 페이징을 클릭하면 해당 영역으로 이동, 필요시 지정해 줘야 기능 작동
            }}
            navigation={
              {
                nextEl: `.event${props.loc_idx} .swiper-button-next`,
                prevEl: `.event${props.loc_idx} .swiper-button-prev`,
              }
            }
            modules={[Autoplay, Pagination, Navigation]}
            className="listModuleSlider"
            wrapperClass="SliderContent"
          >
            {
              props.data.contents.map((item, index) => {


                const handleOpenPopup = () => {
                  //  485 * 928
                  const popup = window.open(item.talk_link, '타이틀명을 입력하시오', 'top=0, left=0, width=485, height=928, status=no, menubar=no, toolbar=no, resizable=no');
                }
                return (
                  item.pc_view_yn === 'Y' ? (
                    <>
                      < SwiperSlide key = {index} >
                        <div className="listModuleItem swiper-slide">
                          <div className="moduleBoxWrap_event">
                            <Link   href={item.link_url} >
                              <a target={item.link_new_yn == 'Y' ? '_blank' : ''} className="mb_link"  onClick={(e) => handleClick(e, item,index,'인기')}>
                                <div className="mb_image_w">
                                  <div className="mb_image_inner">
                                    <Image
                                      className="mb_image"
                                      width={384}
                                      height={(384 / 300) * 159}
                                      layout="fill"
                                      src={item.pc_img_url}
                                      alt={item.pc_img_attr}
                                    />
                                  </div>
                                </div>
                                <div className="mb_info_w">
                                  <span className="mbif_title">{item.title}</span>
                                </div>
                              </a>
                            </Link>
                          </div>
                        </div>
                      </SwiperSlide>
                    </>
                  ) : (<></>)
                );
              })
            }
          </Swiper>

          <div className="swiper-pagination"></div>
          <button type="button" aria-label="이전 콘텐츠 보기" className="btnTypeList-Prev swiper-button-prev" onClick={() => setNaviCount(naviCount - 1)}>
            <span className="iconSvg_arrow"><i className="hide">이전</i></span>
            <span className="pagingNumber_w"><strong className="pagingNumber-now">{naviCount}</strong>/{maxPageCount}</span>
          </button>
        </div>
      </div>
    </>
  );

  // return (
  //     <>
  //         <div className="mainContents-typeWhite">
  //             <div className="contentsBlock_w">
  //                 <div className="moduleTitleline_w">
  //                     <h2 className="moduleTitleline-Title">{props.data.list_title}</h2>
  //                     <a href={props.data.alllink_url} className="moduleTitleline-linkMore" style={{ visibility: (props.data.alllink_yn == 'Y' && props.data.alllink_url != '' && props.data.alllink_url != null) ? 'visible' : 'hidden' }}>{props.data.alllink_title}</a>
  //                 </div>
  //                 <div className="listModule_w_event2">
  //                     <button type="button" aria-label="다음 콘텐츠 보기" className="btnTypeList-Next swiper-button-next" onClick={() => setNaviCount(naviCount + 1)}>
  //                         <span className="iconSvg_arrow"><i className="hide">다음</i></span>
  //                         <span className="pagingNumber_w"><strong className="pagingNumber-now">{naviCount}</strong>/{maxPageCount}</span>
  //                     </button>

  //                     <Swiper
  //                         onSwiper={setSwiperRef}
  //                         slidesPerView={3}
  //                         spaceBetween={24}
  //                         slidesPerGroup={3}
  //                         loop={false}
  //                         pagination={{ // 페이징 설정
  //                             el: `.listModule_w_event2 .swiper-pagination`,
  //                             clickable: false, // 페이징을 클릭하면 해당 영역으로 이동, 필요시 지정해 줘야 기능 작동
  //                         }}
  //                         navigation={
  //                             {
  //                                 nextEl: `.listModule_w_event2 .swiper-button-next`,
  //                                 prevEl: `.listModule_w_event2 .swiper-button-prev`,
  //                             }
  //                         }
  //                         modules={[Autoplay, Pagination, Navigation]}
  //                         className="listModuleSlider"
  //                         wrapperClass="SliderContent"
  //                     >
  //                         {
  //                             props.data.contents.map((item, index) => {

  //                                 const handleOpenPopup = () => {
  //                                     //  485 * 928
  //                                     const popup = window.open(item.talk_link, '타이틀명을 입력하시오', 'top=0, left=0, width=485, height=928, status=no, menubar=no, toolbar=no, resizable=no');
  //                                 }
  //                                 return (
  //                                     < SwiperSlide >
  //                                         <div className="listModuleItem swiper-slide">
  //                                             <div className="moduleBoxWrap_event">
  //                                                 <a href={item.link_url} className="mp_link">
  //                                                     <div className="mb_image_w">
  //                                                         <div className="mb_image_inner">
  //                                                             <img src={item.pc_img_url} className="mp_image" alt={item.pc_img_attr} />
  //                                                         </div>
  //                                                     </div>
  //                                                     <div className="mb_info_w">
  //                                                         <span className="mbif_title">{item.title}</span>
  //                                                     </div>
  //                                                 </a>
  //                                             </div>
  //                                         </div>
  //                                     </SwiperSlide>
  //                                 );
  //                             })
  //                         }
  //                     </Swiper>

  //                     <div className="swiper-pagination"></div>
  //                     <button type="button" aria-label="이전 콘텐츠 보기" className="btnTypeList-Prev swiper-button-prev" onClick={() => setNaviCount(naviCount - 1)}>
  //                         <span className="iconSvg_arrow"><i className="hide">이전</i></span>
  //                         <span className="pagingNumber_w"><strong className="pagingNumber-now">{naviCount}</strong>/{maxPageCount}</span>
  //                     </button>
  //                 </div>
  //             </div>
  //         </div>
  //     </>
  // );

}