import Axios from 'axios';
import cookieCutter from 'cookie-cutter';
import Image from 'next/image';
import Link from 'next/link';
import React, { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { getClickLinkParams } from '../../../../common/utils/appLinkUtil';
import axios from "axios";

// import ShareBox from '../../hotissue/box/ShareBox';

export default function PGMBoxBTNArea(props) {
  const [swiperRef, setSwiperRef] = useState(null);
  let [maxPageCount, setMaxPageCount] = useState(0);
  let [naviCount, setNaviCount] = useState(1);

  const [subscribeClick, setSubscribeClick] = useState(false);
  // let subscribeClick = false;
  //jwt상태값
  const [subscribeJWTCheck, setSubscribeJWTCheck] = useState(false);
  //회원 상태값이 로그인 구독 누르기전 상태값
  const [pre_subscribeClick, setpre_SubscribeClick] = useState(false);

  //구독한뒤 상태값
  const [after_subscribeClick, setafter_SubscribeClick] = useState(false);
  //구독한뒤 상태값(아이콘체킹)
  const [after_subscribeClick2, setafter_SubscribeClick2] = useState(false);
  //구독취소용 최종구독상태 -> 구독 팝업 나서 하나 + 초기로딩시 api 을 통해서 하는거 하나
  const [final_check, setfinal_check] = useState(false);


  const [fetchedData, setFetchedData] = useState([]);
  const [subscri, setsubscri] = useState(false);

  let isMatched = fetchedData.some(item => item.pgm_id === props.data.pgm_id);
  //let count = fetchedData.length;


  useEffect(() => {
    //alert('www');
    window.__SBSLogModuleDataLayer = window.__SBSLogModuleDataLayer || [];
  }, []);

  useEffect(() => {
    //alert('www== ' + fetchedData);
    async function fetchData() {

      const LOGIN_JWT = cookieCutter.get('LOGIN_JWT'); //로그인 토큰 가져오는 부분(로그아웃이 헤더에서 사용되므로 액션 마다 체크해주자)
      const platformCookie = cookieCutter.get('JOIN_PLATFORM');
      if (LOGIN_JWT != undefined) {

        const apiUrl = `https://apis.sbs.co.kr/allvod-api/subscription_pgm_list_main?jwt-token=${LOGIN_JWT}&platform=${platformCookie}`;

        try {
          const response = await fetch(apiUrl);

          // Check if the response is successful
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }

          const data = await response.json();
          //alert(data.length);
          // assuming the data structure is similar to the example you provided
          setFetchedData(data);
          //isMatched = false;
          if (LOGIN_JWT == undefined) {
            setafter_SubscribeClick2(false);
            setfinal_check(true);
          }
        } catch (error) {
          console.error("There was a problem with the fetch operation:", error);
        }
      }
    }
    fetchData();
  }, []);

  // ... rest of your component ...

  // 여기에 useEffect를 추가합니다.
  useEffect(() => {
   // alert(after_subscribeClick2);
  }, [subscribeJWTCheck]);
  const handleClick_custom = (a,b,c,d) => {


    window.__SBSLogModuleDataLayer.push({
      type: 'click',
      option: {
        clickName: '프로그램테마 큐레이션 메뉴버튼',
        clickType: 'NC',
        value1: props.data.pgm_nm,
        value2: props.data.pgm_id,
        value3: '',
        value4: '',
        value5: ''
      }
    });

  };


  function loginCheckPopConfirm() {
    setSubscribeJWTCheck(false);
    window.location.href = "https://join.sbs.co.kr/login/login.do?returnUrl=" + encodeURIComponent(window.location.href);
  }
  const presubscribe = () => {
    setpre_SubscribeClick(true);

  }
  const subscribe_cancel = (type) => {
    if (type == '1') {
      setpre_SubscribeClick(false);
      setafter_SubscribeClick(false);
      setafter_SubscribeClick2(false);
    } else {
      setpre_SubscribeClick(false);
      setafter_SubscribeClick(false);
      setafter_SubscribeClick2(true);
      isMatched = false;
    }

    return;
  }

  const subscribe_confirm = () => {
    setpre_SubscribeClick(false);
    setafter_SubscribeClick(false);
    setafter_SubscribeClick2(true);

    return;
  }

  const subscribe_confirm_limit = () => {
    setpre_SubscribeClick(false);
    setafter_SubscribeClick(false);
    setafter_SubscribeClick2(false);

    return;
  }


  //구독취소
  const unsubscribe = async (a) => {
    const LOGIN_JWT = cookieCutter.get('LOGIN_JWT'); //로그인 토큰 가져오는 부분(로그아웃이 헤더에서 사용되므로 액션 마다 체크해주자)
    const platformCookie = cookieCutter.get('JOIN_PLATFORM');
    const url = "https://apis.sbs.co.kr/allvod-api/subscription_pgm_off";

    // You'd need to get your token from wherever you're storing it.
    const token = LOGIN_JWT; // Replace this with actual token retrieval

    const headers = {
      "Content-Type": "application/json",
      "jwt-token": token,
      "platform": platformCookie // Replace with your platform
    };

    const body = {
      programid: a // This seems static in your example, adapt as needed
    };

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(body)
      });

      const data = await response.json();

      if (response.ok) {
        // Handle success logic here
        setpre_SubscribeClick(false);
        setafter_SubscribeClick(false);
        setafter_SubscribeClick2(false);
        setfinal_check(true);


      } else {
        // Handle error logic here
        console.error("Error unsubscribing:", data);
      }
    } catch (error) {
      console.error("Network error:", error);
    }
  }

  // const subscribe_ck = async () => {
  //   //구독
  //   const LOGIN_JWT = cookieCutter.get('LOGIN_JWT'); //로그인 토큰 가져오는 부분(로그아웃이 헤더에서 사용되므로 액션 마다 체크해주자)
  //   const platformCookie = cookieCutter.get('JOIN_PLATFORM');
  //   const apiUrl = `https://apis.sbs.co.kr/allvod-api/subscription_pgm_list_main?jwt-token=${LOGIN_JWT}&platform=${platformCookie}`;
  //   try {
  //     const response = await axios.get(apiUrl);  // axios를 사용하여 API 호출
  //     alert('jkhk== ' + response);
  //
  //     if (response.data.result === 'Y') {  // 예상 응답 형식에 따라 수정
  //       return true;
  //     }
  //   } catch (error) {
  //     console.error("Error checking subscription:", error);
  //   }
  //   return false;
  // };


  const subscribe = async () => {

    window.__SBSLogModuleDataLayer.push({
      type: 'click',
      option: {
        clickName: '프로그램테마 큐레이션 구독버튼',
        clickType: 'NC',
        value1: props.data.pgm_nm,
        value2: props.data.pgm_id,
        value3: '',
        value4: '',
        value5: ''
      }
    });

    event.preventDefault();
    setsubscri(false);
    setafter_SubscribeClick(false);
    //setafter_SubscribeClick2(false);
    //구독
    const LOGIN_JWT = cookieCutter.get('LOGIN_JWT'); //로그인 토큰 가져오는 부분(로그아웃이 헤더에서 사용되므로 액션 마다 체크해주자)
    const platformCookie = cookieCutter.get('JOIN_PLATFORM');
    if (LOGIN_JWT == null) {
      setSubscribeJWTCheck(true);

      return;
    } else if (LOGIN_JWT != null && pre_subscribeClick == false) {
      setpre_SubscribeClick(true);
      return;
    }

    const subscribe_ck = async () => {
      //구독
      const LOGIN_JWT = cookieCutter.get('LOGIN_JWT'); //로그인 토큰 가져오는 부분(로그아웃이 헤더에서 사용되므로 액션 마다 체크해주자)
      const platformCookie = cookieCutter.get('JOIN_PLATFORM');
      const apiUrl = 'https://apis.sbs.co.kr/allvod-api/subscription_pgm_list_check?jwt-token='+LOGIN_JWT+"&platform="+platformCookie;
      try {
        const response = await axios.get(apiUrl);  // axios를 사용하여 API 호출



        if (response.data.flag === 'Y') {  // 예상 응답 형식에 따라 수정
          return true;
        }
      } catch (error) {

        console.error("Error checking subscription:", error);
      }
      return false;
    };

    const isSubscribed = await subscribe_ck(); // API 확인

    if(isSubscribed == false) {

      setsubscri(true);
      setafter_SubscribeClick2(false);
      return;
    }else{
      setsubscri(false);
      //alert('isSubscribed=' +isSubscribed)

      let url = "";
      let bodyData;


      if (props.data.subscribeState == true) {
        //구독 취소
        url = "https://apis.sbs.co.kr/allvod-api/subscription_pgm_off";
        bodyData = props.data;
        //TODO childmild _id찾기 체크(all페이지와, 찜차이때문)
        if ((props.data.pgm_id != null && props.data.pgm_id != '') || (props.data.programid != null && props.data.programid != '')) {
          bodyData = props.data;
        } else {
          alert('구독 취소 하실수 없습니다.');
          return;
        }

      } else {
        //구독
        url = "https://apis.sbs.co.kr/allvod-api/subscription_pgm";
        bodyData = props.data;
        if ((props.data.pgm_id != null && props.data.pgm_id != '') || (props.data.programid != null && props.data.programid != '')) {
          url = "https://apis.sbs.co.kr/allvod-api/subscription_pgm";
          bodyData = props.data;
          setafter_SubscribeClick(true);
          setfinal_check(true);
          //setafter_SubscribeClick2(true);


        } else {
          alert('구독 하실수 없습니다.');
          return;
        }
      }


      const config = {
        headers: {
          'jwt-token': LOGIN_JWT,
          'platform': platformCookie,
        }
      };

      // let bodyData = props.data; //api 에서 삭제일때는 id만 바라보고, 찜을할때는 풀데이터를 쌓는구조이므로 걍 다쏘자
      Axios.post(url, bodyData, config).then(function (response) {
        if (response.data.message !== null) {
          if (props.data.subscribeState != true) {//취소일때는 아님
            //window.location.href = '/hotissue/subscribe';
          } else {
            //TODO // 현재창 리플레쉬
            window.location.reload();
          }
        }
      }).catch(function (error) {
        console.error(error);
      }).finally(() => {
        // setEventCheck(false);
      });

    }

  };

  const handleOpenPopup = (talk_id) => {
    window.__SBSLogModuleDataLayer.push({
      type: 'click',
      option: {
        clickName: '프로그램테마 큐레이션 플레이톡버튼',
        clickType: 'NC',
        value1: props.data.pgm_nm,
        value2: props.data.pgm_id,
        value3: '',
        value4: '',
        value5: ''
      }
    });



    //  485 * 928
    const popup = window.open("https://www.sbs.co.kr/playtalk/module.html?code=" + talk_id, '타이틀명을 입력하시오', 'top=0, left=0, width=485, height=928, status=no, menubar=no, toolbar=no, resizable=no');
  }

  if (subscribeJWTCheck == true) {
    return (
        <>
        <div className="moduleBottomBtns">
          {/*<a href="#none" className="btnType-subscribe" onClick={() => subscribe(true)}>*/}
          {/*  <span className="iconSvg_subscribe"><i className="hide">구독</i></span>*/}
            {/*</a>*/}

          <div className={subscribeJWTCheck == true ? 'layerSubscribe_w current' : 'layerSubscribe_w'}>
            {/* <div className="layerSubscribe_w "> */}
            <div className="layerSubscribe_inner">
              <p className="layerSubscribe-text">로그인 후 이용이 가능합니다. 로그인 하시겠습니까?</p>
              <div className="layerSubscribe-btns">
                <a href="#none" className="layerSubscribe-btn" onClick={() => setSubscribeJWTCheck(false)}>취소</a>
                <a href="#none" className="layerSubscribe-btn" onClick={() => loginCheckPopConfirm()}>확인</a>
                </div>
              </div>
            </div>
            <a href="#none" onClick={() => handleOpenPopup(props.data.talk_id)} className="btnType-playtalk" style={{ visibility: (props.data.talk_view_yn === 'Y' ? 'visible' : 'hidden') }}><span className="iconSvg_playtalk"></span><strong className="btnType-title">플레이톡</strong></a>
            <Link href={props.data.button_link}>
              <a  onClick={(e) => handleClick_custom(e, '','0','all')}  target={props.data.link_new_yn === 'Y' ? '_blank' : ''} className="btnType-roundGray" style={{ visibility: (props.data.button_view_yn === 'Y' ? 'visible' : 'hidden') }}><strong className="btnType-title" >{props.data.button_nm}</strong></a>
            </Link>
          </div>
        </>
    );
  } else if (subscribeJWTCheck != true) {

    if(after_subscribeClick == false  &&  final_check == false && subscri == false ) {


      return (
          <>
          <div className="moduleBottomBtns">
              <a href="#none"
              className={after_subscribeClick2 == true || isMatched ? 'btnType-subscribe current' : 'btnType-subscribe'}
                 onClick={() => subscribe(true)}>


              <span className="iconSvg_subscribe"><i className="hide">구독</i></span>
              </a>

            <div className={pre_subscribeClick == true ? 'layerSubscribe_w current' : 'layerSubscribe_w'}>
              {/* <div className="layerSubscribe_w "> */}


              <div className="layerSubscribe_inner">


                  { after_subscribeClick2 == true || isMatched
                      ? <p className="layerSubscribe-text">{props.data.pgm_nm} 구독을 취소하시겠습니까?</p>
                      : <p className="layerSubscribe-text">{props.data.pgm_nm} 구독을 하시겠습니까?<br/> *핫이슈 내구독 탭에서 즐기세요!</p>
                  }


                <div className="layerSubscribe-btns">
                    { after_subscribeClick2 == true || isMatched
                    ? <a href="#none" className="layerSubscribe-btn" onClick={() => subscribe_cancel('0')}>취소</a>
                        : <a href="#none" className="layerSubscribe-btn" onClick={() => subscribe_cancel('1')}>취소</a>
                    }
                    {after_subscribeClick2 == true || isMatched
                        ? <a href="#none" className="layerSubscribe-btn"
                             onClick={() => unsubscribe(props.data.pgm_id)}>확인</a>
                        : <a href="#none" className="layerSubscribe-btn" onClick={() => subscribe(true)}>확인</a>
                    }
                  </div>


                </div>
              </div>


              <a href="#none" onClick={() => handleOpenPopup(props.data.talk_id)} className="btnType-playtalk"
                 style={{visibility: (props.data.talk_view_yn === 'Y' ? 'visible' : 'hidden')}}><span
                  className="iconSvg_playtalk"></span><strong className="btnType-title">플레이톡</strong></a>
              <Link href={props.data.button_link}>
                <a  onClick={(e) => handleClick_custom(e, '','0','all')} target={props.data.link_new_yn === 'Y' ? '_blank' : ''} className="btnType-roundGray"
                   style={{visibility: (props.data.button_view_yn === 'Y' ? 'visible' : 'hidden')}}><strong
                    className="btnType-title">{props.data.button_nm} {props.data.programid}</strong></a>
              </Link>
            </div>
          </>
      );
    }else if(after_subscribeClick == false  && final_check == true && subscri == false ) {
      //const isMatched = fetchedData.some(item => item.pgm_id === props.data.pgm_id);
      return (
          <>
          <div className="moduleBottomBtns">
              <a href="#none"
              className={after_subscribeClick2 == true ? 'btnType-subscribe current' : 'btnType-subscribe'}
                 onClick={() => subscribe(true)}>


              <span className="iconSvg_subscribe"><i className="hide">구독</i></span>
              </a>

            <div className={pre_subscribeClick == true ? 'layerSubscribe_w current' : 'layerSubscribe_w'}>
              {/* <div className="layerSubscribe_w "> */}


              <div className="layerSubscribe_inner">


                  {after_subscribeClick2 == true

                      ? <p className="layerSubscribe-text">{props.data.pgm_nm} 구독을 취소하시겠습니까?</p>
                      : <p className="layerSubscribe-text">{props.data.pgm_nm} 구독을 하시겠습니까?<br/>*핫이슈 내구독 탭에서 즐기세요!</p>
                  }


                  <div className="layerSubscribe-btns">
                    {after_subscribeClick2 == true
                        ? <a href="#none" className="layerSubscribe-btn" onClick={() => subscribe_cancel('0')}>취소</a>
                        : <a href="#none" className="layerSubscribe-btn" onClick={() => subscribe_cancel('1')}>취소</a>
                    }
                    {after_subscribeClick2 == true
                        ? <a href="#none" className="layerSubscribe-btn"
                             onClick={() => unsubscribe(props.data.pgm_id)}>확인</a>
                        : <a href="#none" className="layerSubscribe-btn" onClick={() => subscribe(true)}>확인</a>
                    }
                  </div>


                </div>
              </div>


              <a href="#none" onClick={() => handleOpenPopup(props.data.talk_id)} className="btnType-playtalk"
                 style={{visibility: (props.data.talk_view_yn === 'Y' ? 'visible' : 'hidden')}}><span
                  className="iconSvg_playtalk"></span><strong className="btnType-title">플레이톡</strong></a>
              <Link href={props.data.button_link}>
                <a  onClick={(e) => handleClick_custom(e, '','0','all')} target={props.data.link_new_yn === 'Y' ? '_blank' : ''} className="btnType-roundGray"
                   style={{visibility: (props.data.button_view_yn === 'Y' ? 'visible' : 'hidden')}}><strong
                    className="btnType-title">{props.data.button_nm} {props.data.programid}</strong></a>
              </Link>
            </div>
          </>
      );

    }else if(after_subscribeClick == true && subscri == false ) {
      //const isMatched = fetchedData.some(item => item.pgm_id === props.data.pgm_id);

      return (
          <>
          <div className="moduleBottomBtns">
              <a href="#none"
                 className={after_subscribeClick2 == true || isMatched ? 'btnType-subscribe current' : 'btnType-subscribe'}
                 onClick={() => subscribe(true)}>>
              <span className="iconSvg_subscribe"><i className="hide">구독</i></span>
              </a>

            <div className={pre_subscribeClick == true ? 'layerSubscribe_w current' : 'layerSubscribe_w'}>
              {/* <div className="layerSubscribe_w "> */}
              <div className="layerSubscribe_inner">
                <p className="layerSubscribe-text">{props.data.pgm_nm} 구독이 완료되었습니다.<br />*핫이슈 내구독 탭에서 즐기세요!</p>
                {/*<p className="layerSubscribe-text">'핫이슈 내구독 탭에서 즐기세요!'</p>*/}
                <div className="layerSubscribe-btns">

                  <a href="#none" className="layerSubscribe-btn" onClick={() => subscribe_confirm()}>확인</a>
                  </div>
                </div>
              </div>


              <a href="#none" onClick={() => handleOpenPopup(props.data.talk_id)} className="btnType-playtalk"
                 style={{visibility: (props.data.talk_view_yn === 'Y' ? 'visible' : 'hidden')}}><span
                  className="iconSvg_playtalk"></span><strong className="btnType-title">플레이톡</strong></a>
              <Link href={props.data.button_link}>
                <a  onClick={(e) => handleClick_custom(e, '','0','all')} target={props.data.link_new_yn === 'Y' ? '_blank' : ''} className="btnType-roundGray"
                   style={{visibility: (props.data.button_view_yn === 'Y' ? 'visible' : 'hidden')}}><strong
                    className="btnType-title">{props.data.button_nm}  {props.data.programid}</strong></a>
              </Link>
            </div>
          </>
      );

    } else if (subscri == true) {
      //const isMatched = fetchedData.some(item => item.pgm_id === props.data.pgm_id);

      return (
          <>
          <div className="moduleBottomBtns">
              <a href="#none"
                 className={subscri != true  ? 'btnType-subscribe current' : 'btnType-subscribe'}
                 onClick={() => subscribe(true)}>
              <span className="iconSvg_subscribe"><i className="hide">구독</i></span>
              </a>

            <div className={pre_subscribeClick == true ? 'layerSubscribe_w current' : 'layerSubscribe_w'}>
              {/* <div className="layerSubscribe_w "> */}
              <div className="layerSubscribe_inner">
                <p className="layerSubscribe-text">프로그램 최대 구독 개수는 10개입니다.</p>
                <div className="layerSubscribe-btns">
                  <a href="#none" className="layerSubscribe-btn" onClick={() => subscribe_confirm_limit()}>확인</a>
                  </div>
                </div>
              </div>


              <a href="#none" onClick={() => handleOpenPopup(props.data.talk_id)} className="btnType-playtalk"
                 style={{visibility: (props.data.talk_view_yn === 'Y' ? 'visible' : 'hidden')}}><span
                  className="iconSvg_playtalk"></span><strong className="btnType-title">플레이톡</strong></a>
              <Link href={props.data.button_link}>
                <a onClick={(e) => handleClick_custom(e, '','0','all')} target={props.data.link_new_yn === 'Y' ? '_blank' : ''} className="btnType-roundGray"
                   style={{visibility: (props.data.button_view_yn === 'Y' ? 'visible' : 'hidden')}}><strong
                    className="btnType-title">{props.data.button_nm} {props.data.programid}</strong></a>
              </Link>
            </div>
          </>
      );

    }
  }


}