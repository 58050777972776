import Image from 'next/image';
import Link from 'next/link';
import React, { useEffect, useRef, useState } from 'react';
import { getClickLinkParams } from '../../../../common/utils/appLinkUtil';

export default function free3Box(props) {

  // 모듈러 링크 리액트 hook
  useEffect(() => {
    window.__SBSLogModuleDataLayer = window.__SBSLogModuleDataLayer || [];
  }, []);
  //link로그를
  const handleClick = (a, b, c,d) => {
    //통계에 넙겨줄 값을정리한다,상단여역 pgmbox 프로그램 / 메뉴 / 플레이톡이 있음
    let contentId;
    let contentTitle;
    window.__SBSLogModuleDataLayer.push({
      type: 'content',
      option: {
        moduleId: '', //모듈 아이디 없으면
        moduleTitle: '상단영역', //모듈 제목 (Require)
        moduleSubTitle: '자유형3', //모듈 서브제목
        moduleActive: 'C', //콘텐츠 선택 정보 Click: C, Hover: H
        moduleSeq: 0, //페이지에서 해당 모듈의 위치
        moduleItemSeq: d, //모듈 내에서 콘텐츠의 위치
        programId: '', //프로그램의 아이디 (추가)
        programTitle: '', //프로그램의 제목 (추가)
        contentId: '자유형3',//식별자
        contentTitle: b.pc_img_attr, //콘텐츠의 제목  (Require)
        contentType: 'LK', //콘텐츠의 타입 (별도 표 참조)
      }
    });
  };

  //console.log(props.data.contents[2])
  return (
    <div className="swiper-slide slide-slide">
      <div className="swiper-cont">
        <div className="spotModuleGroup2">

          <div className="spotModuleGroup2-2">
            {/* <!-- 자유형 : type1 : 포토 높이 반 --> */}
            <div className={`spotModuleWrap_freeType1-1 spotModuleWrap_ContentPhoto ${props.data.contents[0].title == '' || props.data.contents[0].title == null ? 'spotModuleWrap_freeTypeBnr' : ''}`}>
              {
                props.data.contents[0].link_url == null || props.data.contents[0].link_url == ''
                  ? (
                    <>
                      <div className="spotModuleLink">
                        <span className="spotModuleImage_w">
                          <Image
                            src={props.data.contents[0].pc_img_url}
                            className="spotModuleImg"
                            layout='fixed'
                            width={588}
                            height={213}
                            quality={props.data.contents[0].imageQuality || 75}
                            alt={props.data.contents[0].pc_img_attr}
                          />
                        </span>
                        <div className="spotProgramCont">
                          {/* replaceBrTag({props.data.contents[0].title}) */}
                          <strong className="spotProgram-Title" style={{ whiteSpace: 'pre-line', height: '100' }}>{props.data.contents[0].title}</strong>
                        </div>
                      </div>
                    </>
                  )
                  :
                  (
                    <>
                      <a href={props.data.contents[0].link_url} >
                        <a onClick={(e) => handleClick(e, props.data.contents[0], '3free','1')} target={props.data.contents[0].link_new_yn == 'Y' ? '_blank' : ''} className="spotModuleLink">
                          <span className="spotModuleImage_w">
                            <Image
                              src={props.data.contents[0].pc_img_url}
                              className="spotModuleImg"
                              layout='fixed'
                              width={588}
                              height={213}
                              quality={props.data.contents[0].imageQuality || 75}
                              alt={props.data.contents[0].pc_img_attr}
                            />
                          </span>
                          <div className="spotProgramCont">
                            {/* replaceBrTag({props.data.contents[0].title}) */}
                            <strong className="spotProgram-Title" style={{ whiteSpace: 'pre-line', height: '100' }}>{props.data.contents[0].title}</strong>
                          </div>
                        </a>
                      </a>
                    </>
                  )
              }


            </div>

            {/* <!-- 자유형 : type1 : 배너타입 --> */}
            <div className={`spotModuleWrap_freeType1-1 spotModuleWrap_ContentPhoto ${props.data.contents[1].title == '' || props.data.contents[1].title == null ? 'spotModuleWrap_freeTypeBnr' : ''}`}>
              {
                props.data.contents[1].link_url == null || props.data.contents[1].link_url == ''
                  ? (
                    <>
                      <div className="spotModuleLink">
                        <span className="spotModuleImage_w">
                          <Image
                            src={props.data.contents[1].pc_img_url}
                            className="spotModuleImg"
                            layout='fixed'
                            width={588}
                            height={213}
                            quality={props.data.contents[1].imageQuality || 75}
                            alt={props.data.contents[1].pc_img_attr}
                          />
                        </span>
                        <div className="spotProgramCont">
                          <strong className="spotProgram-Title" style={{ whiteSpace: 'pre-line' }}>{props.data.contents[1].title}</strong>
                        </div>
                      </div>
                    </>
                  )
                  :
                  (
                    <>
                      <a href={props.data.contents[1].link_url} >
                        <a onClick={(e) => handleClick(e, props.data.contents[1], '3free22','2')} target={props.data.contents[1].link_new_yn == 'Y' ? '_blank' : ''} className="spotModuleLink">
                          <span className="spotModuleImage_w">
                            <Image
                              src={props.data.contents[1].pc_img_url}
                              className="spotModuleImg"
                              layout='fixed'
                              width={588}
                              height={213}
                              quality={props.data.contents[1].imageQuality || 75}
                              alt={props.data.contents[1].pc_img_attr}
                            />
                          </span>
                          <div className="spotProgramCont">
                            <strong className="spotProgram-Title" style={{ whiteSpace: 'pre-line' }}>{props.data.contents[1].title}</strong>
                          </div>
                        </a>
                      </a>
                    </>
                  )
              }

            </div>
            {/* 아래주석 왜쓰는지 확인해봐야할듯 기획서 상에는 없어도 되는 아이임*/}
            {/* <div className="spotModuleWrap_freeTypeBnr spotModuleWrap_ContentPhoto">
                            <a href={props.data.contents[1].link_url} className="spotModuleLink">
                                <span className="spotModuleImage_w">
                                    <img src={props.data.contents[1].pc_img_url} className="spotModuleImg" alt={props.data.contents[1].pc_img_attr} />
                                </span>
                            </a>
                        </div> */}
          </div>
          {/* <!-- 자유형 : type1 : 포토 기본 --> */}
          <div className={`spotModuleWrap_freeType1  spotModuleWrap_ContentPhoto ${props.data.contents[2].title == '' || props.data.contents[2].title == null ? 'type_noText' : ''}`}>
            {
              props.data.contents[2].link_url == null || props.data.contents[2].link_url == ''
                ? (
                  <>
                    <div className="spotModuleLink">
                      <span className="spotModuleImage_w">
                        <Image
                          src={props.data.contents[2].pc_img_url}
                          className="spotModuleImg"
                          layout='fixed'
                          width={588}
                          height={450}
                          quality={props.data.contents[2].imageQuality || 75}
                          alt={props.data.contents[2].pc_img_attr}
                        />
                      </span>
                      <div className="spotProgramCont">
                        <strong className="spotProgram-Title" >{props.data.contents[2].title}</strong>
                      </div>
                    </div>
                  </>
                )
                :
                (
                  <>
                    <a href={props.data.contents[2].link_url} >
                      <a onClick={(e) => handleClick(e, props.data.contents[2], '3free','3')} target={props.data.contents[2].link_new_yn == 'Y' ? '_blank' : ''} className="spotModuleLink">
                        <span className="spotModuleImage_w">
                          <Image
                            src={props.data.contents[2].pc_img_url}
                            className="spotModuleImg"
                            layout='fixed'
                            width={588}
                            height={450}
                            quality={props.data.contents[2].imageQuality || 75}
                            alt={props.data.contents[2].pc_img_attr}
                          />
                        </span>
                        <div className="spotProgramCont">
                          <strong className="spotProgram-Title" >{props.data.contents[2].title}</strong>
                        </div>
                      </a>
                    </a>
                  </>
                )
            }
          </div>

        </div >
      </div >
    </div >

  );
}
