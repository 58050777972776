import Image from 'next/image';
import Link from 'next/link';
import React, { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { toPercentTime, toPercentTimeDesc, updateQueryStringParameter } from '../../../../common/utils/convertUtil';

export default function clip_Box(props) {

  const [swiperRef, setSwiperRef] = useState(null);
  let [maxPageCount, setMaxPageCount] = useState(0);
  let [naviCount, setNaviCount] = useState(1);

  useEffect(() => {
    if (swiperRef != null) {
      swiperRef.slideTo(0, 0); //TAB 영역 누르면 스와이퍼 초기화
    }

    setMaxPageCount(props.data.contents.length / 4);

  }, [])
  useEffect(() => {
    //alert('www');
    window.__SBSLogModuleDataLayer = window.__SBSLogModuleDataLayer || [];
  }, []);
  //link로그를
  const handleClick = (a,b,c,d,e) => {
    //통계에 넙겨줄 값을정리한다,상단여역 pgmbox 프로그램 / 메뉴 / 플레이톡이 있음
    window.__SBSLogModuleDataLayer.push({
      type: 'content',
      option: {
        moduleId: '큐레이션 모듈', //모듈 아이디 없으면
        moduleTitle: e, //모듈 제목 (Require)
        moduleSubTitle: '클립 큐레이션',
        moduleActive: 'C', //콘텐츠 선택 정보 Click: C, Hover: H
        moduleSeq: props.loc_idx, //페이지에서 해당 모듈의 위치
        moduleItemSeq: c, //모듈 내에서 콘텐츠의 위치
        programId: b.pgm_id, //프로그램의 아이디 (추가)
        programTitle: b.pgm_nm, //프로그램의 아이디 (추가)
        contentId:  b.mda_id,//식별자
        contentTitle: b.img_attr, //콘텐츠의 제목  (Require)
        contentNumber: '',
        contentType: 'C', //콘텐츠의 타입 (별도 표 참조)
      }
    });
  };


  return (
    <>
      <div className="mainContents-typeWhite">
        {/* <!-- 클립 큐레이션 --> */}
        <div className="contentsBlock_w">
          <div className="moduleTitleline_w">
            <h2 className="moduleTitleline-Title">{props.data.list_title}</h2>
            <a href={props.data.alllink_url} className="moduleTitleline-linkMore" style={{ visibility: (props.data.alllink_yn == 'Y' && props.data.alllink_url != '' && props.data.alllink_url != null) ? 'visible' : 'hidden' }}>{props.data.alllink_title}</a>
          </div>
          <div className={"listModule_w_clipCuration clipCuration" + props.loc_idx}>
            <button type="button" aria-label="다음 콘텐츠 보기" className="btnTypeList-Next swiper-button-next" onClick={() => setNaviCount(naviCount + 1)}>
              <span className="iconSvg_arrow"><i className="hide">다음</i></span>
              <span className="pagingNumber_w"><strong className="pagingNumber-now">{naviCount}</strong>/{maxPageCount}</span>
            </button>
            {/* <!-- mySwiper 클래스 추가시 동작 --> */}

            <Swiper
              onSwiper={setSwiperRef}
              slidesPerView={4}
              spaceBetween={24}
              slidesPerGroup={4}
              loop={false}
              pagination={{ // 페이징 설정
                el: `.clipCuration${props.loc_idx} .swiper-pagination`,
                clickable: false, // 페이징을 클릭하면 해당 영역으로 이동, 필요시 지정해 줘야 기능 작동
              }}
              navigation={
                {
                  nextEl: `.clipCuration${props.loc_idx} .swiper-button-next`,
                  prevEl: `.clipCuration${props.loc_idx} .swiper-button-prev`,
                }
              }
              modules={[Autoplay, Pagination, Navigation]}
              className="listModuleSlider"
              wrapperClass="SliderContent"
            >

              {
                props.data.contents.map((item, index) => {

                  const handleOpenPopup = () => {
                    //  485 * 928
                    const popup = window.open(item.talk_link, '타이틀명을 입력하시오', 'top=0, left=0, width=485, height=928, status=no, menubar=no, toolbar=no, resizable=no');
                  }
                  return (
                    < SwiperSlide >
                      <div className="listModuleItem swiper-slide">
                        <div className="moduleBoxWrap_clip">
                          <Link href={item.link_url} >
                            <a onClick={(e) => handleClick(e, item,index,'인기', props.data.list_title)}  target={item.link_new_yn == 'Y' ? '_blank' : ''} className="mb_link">
                              <div className="mb_image_w">
                                <span className="mb_label_time">{item.plytm}</span>
                                <div className="mb_image_inner">
                                  <img src={item.img_url} className="mb_image" alt={item.img_attr} />
                                </div>
                              </div>
                              <div className="mb_info_w">
                                <span className="mbif_title">{item.content}</span>
                                <span className="mbif_subinfo">{item.pgm_nm}</span>
                              </div>
                            </a>
                          </Link>
                        </div>
                      </div>
                    </SwiperSlide>
                  );
                })
              }

            </Swiper>

            <div className="swiper-pagination"></div>
            <button type="button" aria-label="이전 콘텐츠 보기" className="btnTypeList-Prev swiper-button-prev" onClick={() => setNaviCount(naviCount - 1)}>
              <span className="iconSvg_arrow"><i className="hide">이전</i></span>
              <span className="pagingNumber_w"><strong className="pagingNumber-now">{naviCount}</strong>/{maxPageCount}</span>
            </button>
          </div>
        </div>
      </div >
    </>
  );

}